import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { pickBy, difference } from 'lodash'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { camelizeKeys } from 'humps'

import { reportError } from '@mc/monitoring'
// eslint-disable-next-line no-restricted-imports
import { getExperiments, getBucket } from '@mc/experiments'
// eslint-disable-next-line no-restricted-imports
import { getGeo, getRegion } from '@mc/geo'
import { getCurrentLocale, getBrowserLocales } from '@mc/i18n'
import { experiment as rumExperiment, metrics as rumMetrics } from '@mc/rum'

import createOrGetVisitSessionIdFromCookie from './interactors/createOrGetVisitSessionIdFromCookie'
import getFacebookBrowserId from './interactors/getFacebookBrowserId'
import getFacebookClickId from './interactors/getFacebookClickId'
import createEventId from './interactors/createEventId'
import { TRACK_PROPS } from './trackMediaConstants'
import { EventTypes, AD_PARTNERS } from './constants'
import { getCampaignParams, logSegmentCalls } from './utils'
import getAnonymousId from './interactors/getAnonymousId'
import { useAuth0User } from '../hooks/useAuth0User'
import { usePersonaPage } from '../hooks/tracking/usePersonaPage'
import { useMarketingPageSource } from '../hooks'
import { getEmailCookie } from '../utils/cookies'
import { sprigTrackEvent } from '../sprig'

const server = 'persona'

type TrackProperties = Record<
  string,
  string | number | boolean | object | null | undefined
>

type TrackOptions = { integrations?: Record<string, boolean> }

type TrackFn = (
  event: string,
  properties?: TrackProperties,
  options?: TrackOptions,
) => void

export type TrackPageFn = (
  event?: string,
  properties?: TrackProperties,
  options?: TrackOptions,
) => Promise<void>

type PersonaTrackContextType = {
  track: TrackFn
  trackPage: TrackPageFn
  resetSegmentIdentity: () => void
  trackExperimentViewed: (
    experimentName: string,
    properties?: TrackProperties,
  ) => void
  trackExperimentBucketed: (
    experimentName: string,
    properties?: TrackProperties,
  ) => void
  queryParams: ParsedUrlQuery
  isLoggedIn: boolean
  trackGoldStandard: TrackFn
  trackPageGoldStandard: TrackPageFn
  loaded: boolean
}

export const PersonaTrackContext =
  createContext<PersonaTrackContextType | null>(null)

export const usePersonaTrack = () => {
  const ctx = useContext(PersonaTrackContext)
  if (!ctx)
    throw new Error(
      'usePersonaTrack must be used within a PersonaTrackProvider',
    )
  return ctx
}

export const PersonaTrackProvider = ({
  children,
  pageName,
}: {
  children: React.ReactNode
  pageName?: string
}) => {
  const { user: auth0User, isLoading, me, isLoadingMe } = useAuth0User()
  const loaded = useMemo(
    () => !isLoading && !isLoadingMe,
    [isLoading, isLoadingMe],
  )
  const isLoggedIn = useMemo(() => !!auth0User, [auth0User])
  const queryParams = useRouter().query
  const isMCEmail = useMemo(
    () => (isLoggedIn ? auth0User?.email.endsWith('@masterclass.com') : null),
    [auth0User?.email, isLoggedIn],
  )
  const pageResult = usePersonaPage(pageName)
  const pageSource = useMarketingPageSource({ isLoggedIn })

  const getAnalyticsBE = useCallback(() => {
    if (isLoggedIn && me?.analytics) {
      const {
        properties: _,
        userId,
        anonymousId,
      } = camelizeKeys(me.analytics) as {
        userId: string
        anonymousId: string
        properties: { user_plan_status: string }
      }
      return {
        userId,
        anonymousId,
        ...me.analytics.properties,
      }
    }
    return {}
  }, [isLoggedIn, me?.analytics])

  const getCurrentUserTraits = useCallback(() => {
    if (isLoggedIn && loaded && me?.analytics) {
      return {
        userId: me.analytics.userId,
        persona_user_id: me.analytics.personaUserId,
        user_plan_status: me.analytics.properties?.userPlanStatus,
        ...getAnalyticsBE(),
      }
    }
    return {
      anonymousId: getAnonymousId(),
      userId: auth0User?.email || getEmailCookie() || getAnonymousId(),
    }
  }, [isLoggedIn, loaded, me, auth0User?.email, getAnalyticsBE])

  const sharedProperties = useMemo(() => {
    const userTraits = getCurrentUserTraits()
    return {
      ...userTraits,
      ...getCampaignParams(queryParams),
      page: pageResult,
      ...(pageSource && { page_source: pageSource }),
      ...(isMCEmail !== null && { is_mc_email: isMCEmail }),
    }
  }, [getCurrentUserTraits, queryParams, pageResult, pageSource, isMCEmail])

  const addFacebookIds = () => ({
    fbp: getFacebookBrowserId(),
    fbc: getFacebookClickId(),
  })

  // properties passed to all track events
  const getEventProps = useCallback(
    (properties: object): Record<string, unknown> => ({
      ...sharedProperties,
      user_locale: getCurrentLocale(),
      native_locale: getBrowserLocales(),
      geo: getGeo(),
      region: getRegion(),
      event_id: createEventId(),
      platform: 'web',
      [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
      ...addFacebookIds(),
      ...properties,
    }),
    [sharedProperties],
  )

  const getUserExperiments = () => {
    const experiments = pickBy(getExperiments())
    return Object.entries(experiments).map(
      ([name, value]) => `${name}_${value}`,
    )
  }

  const hasIdentifiedRef = useRef(false)

  const identify = useCallback(
    (
      userId?: string | number | null,
      traits = {},
      options: SegmentAnalytics.SegmentOpts = {},
    ) => {
      const userTraits = {
        ...traits,
        experiments: getUserExperiments(),
        user_locale: getCurrentLocale(),
        native_locale: getBrowserLocales(),
        geo: getGeo(),
        region: getRegion(),
        event_id: createEventId(),
        [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
        ...addFacebookIds(),
      }
      if (window.analytics) {
        const integrations = {
          Pardot: false,
          ...options.integrations,
        }
        // identify -- Pardot is off by default
        window.analytics.identify(
          // for backwards compatibility - we need to continue sending `null`
          (userId?.toString() ?? null) as string,
          userTraits,
          {
            ...options,
            integrations,
            vendor_integrations: integrations,
          } as SegmentAnalytics.SegmentOpts,
          () => {
            logSegmentCalls('identify', 'identify', userTraits)
          },
        )
      }
    },
    [],
  )

  useEffect(() => {
    if (!hasIdentifiedRef.current && loaded && isLoggedIn && me?.analytics) {
      const userTraits = {
        userId: me?.analytics.userId,
        persona_user_id: me?.analytics.personaUserId,
        user_plan_status: me?.analytics.properties?.userPlanStatus,
      }
      identify(me?.analytics.userId, userTraits)
      hasIdentifiedRef.current = true
    }
  }, [loaded, isLoggedIn, me, identify])

  const resetSegmentIdentity = () => {
    if (window.analytics) {
      window.analytics.reset()
    }
  }

  const page = useCallback(
    async (
      event: string,
      properties: Record<string, unknown> = {},
      options: TrackOptions = {
        integrations: {},
      },
    ) => {
      const userTraits = {
        ...sharedProperties,
        ...properties,
        user_locale: getCurrentLocale(),
        native_locale: getBrowserLocales(),
        geo: getGeo(),
        region: getRegion(),
        event_id: createEventId(),
        [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
        ...addFacebookIds(),
      }

      const integrations = {
        ...adPartnersTo(!isLoggedIn),
        ...options.integrations,
      }
      const mergedOptions = {
        ...options,
        served_by: server,
        integrations,
        vendor_integrations: integrations,
      }

      if (window.analytics) {
        window.analytics.page(event, userTraits, mergedOptions, () => {
          logSegmentCalls('page', event, userTraits, mergedOptions)
        })
      }
    },
    [isLoggedIn, sharedProperties],
  )

  const checkExperimentViewedCalled = ({
    experimentName,
  }: {
    experimentName?: string
    [k: string]: string | number | boolean | object | null | undefined
  }) => {
    const EXPERIMENT_KEY = 'experiments'
    let calledExperiments = []
    const experimentCookie = window.sessionStorage.getItem(EXPERIMENT_KEY)
    if (experimentCookie) {
      calledExperiments = JSON.parse(experimentCookie)
    }
    const activeExperiments = Object.keys(getExperiments())
    if (!calledExperiments.includes(experimentName)) {
      calledExperiments.push(experimentName)
      const inactiveExperiments = difference(
        calledExperiments,
        activeExperiments,
      )
      calledExperiments = difference(calledExperiments, inactiveExperiments)
      window.sessionStorage.setItem(
        EXPERIMENT_KEY,
        JSON.stringify(calledExperiments),
      )
      return false
    }
    return true
  }

  const adPartnersTo = (value = false) =>
    AD_PARTNERS.reduce(
      (partners, partner) => ({ ...partners, [partner]: value }),
      { All: true },
    )

  const track: TrackFn = useCallback(
    (
      event,
      properties = {},
      options = {
        integrations: adPartnersTo(false),
      },
    ) => {
      if (
        // eslint-disable-next-line
        process.env.NODE_ENV === 'production' &&
        event === EventTypes.EXPERIMENT_VIEWED &&
        checkExperimentViewedCalled(properties)
      ) {
        return
      }

      const mergedProps = getEventProps(properties)
      const mergedOptions = {
        ...options,
        served_by: server,
        // MTECH-198: copying the integrations in the context so it flows into the data warehouse
        // by default, the integrations property is blocked by the data warehouse
        vendor_integrations: {
          ...options.integrations,
        },
      }

      if (typeof window !== 'undefined' && window.analytics) {
        window.analytics.track(event, mergedProps, mergedOptions, () => {
          logSegmentCalls('track', event, mergedProps, mergedOptions)
        })
        sprigTrackEvent(event, mergedProps)
      }
    },
    [getEventProps],
  )

  const trackExperimentViewed = (experimentName: string, properties = {}) => {
    if (getBucket(experimentName)) {
      const variationName = getBucket(experimentName)
      track(EventTypes.EXPERIMENT_VIEWED, {
        experimentName,
        variationName,
        ...properties,
      })

      rumExperiment(experimentName, variationName)
    }
  }

  const trackExperimentBucketed = (experimentName: string, properties = {}) => {
    const variationName = getBucket(experimentName)

    if (variationName) {
      track(EventTypes.EXPERIMENT_BUCKETED, {
        experimentName,
        variationName,
        ...properties,
      })
    }
  }

  const trackPage: TrackPageFn = useCallback(
    async (
      event = '',
      properties = {},
      options = {
        integrations: { ...adPartnersTo(false) },
      },
    ) => {
      try {
        if (event !== '') {
          let mergedProperties: Record<string, unknown> = {
            ...properties,
          }

          // Appending search, url, and path directly to all trackPage calls for client-side page load calls when analyticsLabel is not used in a page to avoid sending on every call
          if (document && window) {
            const { search, pathname: path, href: url } = window.location
            const { referrer, title } = document
            mergedProperties = {
              // if referred is manually sent it takes precedence over the referrer here (document.referrer is only set once and doesn't change for client-side navigation)
              ...(referrer && { referrer }),
              ...mergedProperties,
              title,
              url,
              path,
              search,
            }
          }

          page(event, mergedProperties, options)
        }

        if (typeof window !== 'undefined' && window) {
          rumMetrics(event, (metric) =>
            track('Core Web Vital', {
              ...metric,
              path: window?.location?.pathname,
            }),
          )
        }
      } catch (e) {
        reportError(e as Error)
      }

      return Promise.resolve()
    },
    [page, track],
  )

  const trackGoldStandard: TrackFn = useCallback(
    (
      event,
      properties = {},
      options = {
        integrations: adPartnersTo(true),
      },
    ) => track(event, { ...properties, product: 'oncall' }, options),
    [track],
  )

  const trackPageGoldStandard: TrackPageFn = async (
    event = '',
    properties = {},
    options = {
      integrations: adPartnersTo(true),
    },
  ) => trackPage(event, { ...properties, product: 'oncall' }, options)

  return (
    <PersonaTrackContext.Provider
      value={{
        track,
        trackPage,
        trackExperimentViewed,
        trackExperimentBucketed,
        resetSegmentIdentity,
        queryParams,
        isLoggedIn,
        trackGoldStandard,
        trackPageGoldStandard,
        loaded,
      }}
    >
      {children}
    </PersonaTrackContext.Provider>
  )
}
