import { getEnvVar } from '@mc/client-env'
import { getAnonymousId } from '@mc/track-event'

const log = (eventName: string, properties?: object, metadata?: object) => {
  // eslint-disable-next-line no-process-env
  const shouldLog = process.env.LOG_SURVEY_CALLS as string | boolean
  if (shouldLog === true || shouldLog === 'true') {
    let output = `surveys.log('${eventName}'`
    if (properties && Object.keys(properties).length) {
      output += `, ${JSON.stringify(properties, null, 2)}`
    }
    if (metadata && Object.keys(metadata).length) {
      output += `, ${JSON.stringify(metadata, null, 2)}`
    }
    output += ')'

    console.groupCollapsed(`Survey event: ${eventName}`) // eslint-disable-line
    console.info(`%c ${output}`, 'color: #59a2ea') // eslint-disable-line
    console.groupEnd() // eslint-disable-line
  }
}

const configure = async () => {
  const { sprig } = await import('@sprig-technologies/sprig-browser')

  return new Promise((resolve, reject) => {
    if (window.Sprig !== undefined) {
      resolve(null)
    }

    const environmentId = getEnvVar('PERSONA_SPRIG_KEY') as string

    if (!environmentId) {
      reject(Error('No environmentId'))
    }

    sprig.configure({ environmentId })

    const intervalId = setInterval(() => {
      if (window.Sprig !== undefined) {
        clearInterval(intervalId)
        resolve(null)
      }
    }, 500)
  })
}

const executeSprigSafely = (callback: () => void) => {
  configure().then(() => callback())
}

export const sprigIdentify = (userId?: string) => {
  const anonymousId = getAnonymousId()
  if (userId) {
    executeSprigSafely(() => window.Sprig('setUserId', userId))
    log('setUserId', { userId })
  } else if (anonymousId) {
    executeSprigSafely(() => window.Sprig('setUserId', anonymousId))
    log('setUserId', { anonymousId })
  }
}

export const sprigLogout = () => {
  executeSprigSafely(() => window.Sprig('logoutUser'))
  log('logoutUser')
}

export const sprigTrackEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
  metadata?: Record<string, unknown>,
  showSurveyCallback?: (surveyId?: number) => Promise<boolean>,
) => {
  sprigIdentify(properties?.userId as string | undefined)

  executeSprigSafely(() =>
    window.Sprig('track', eventName, properties, metadata, showSurveyCallback),
  )
  log('track', { eventName, properties, metadata })
}
