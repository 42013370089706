import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Link from 'next/link'
import cn from 'classnames'
import { useRouter } from 'next/router'
import Image from 'next/image'

import { useTranslation } from '@mc/i18n'
import {
  Loader,
  Button,
  DropdownToggle,
  Dropdown,
  DropdownContent,
} from '@mc/design-system'

import Logo from '../../assets/oncall-logo.svg'
import {
  AccountManagementMobile,
  AccountManagementDropdown,
} from '../AccountManagement'
import { Auth0Service } from '../../services/Auth0Service'
import { useAuth0User } from '../../hooks/useAuth0User'
import { usePersonaEvents } from '../../events'
import { paths } from '../../utils/paths'
import { useIsUpcomingBetaEnabled } from '../../experiments'
import { useCtaToPurchaseUrl } from '../../utils/cta'
import { PageSources } from '../../types/tracking'
import { LogoutConfirmationModal } from '../LogoutConfirmationModal'
import { ExtendedSources, getPageSource } from '../../hooks/tracking/events'
import { cloudflareLoader } from '../Image'
import ProfilePlaceholder from '../../assets/mobile-profile-placeholder.svg'

import styles from './NavBar.module.scss'

export const NavBar = ({
  pageName,
  showLogin,
  source,
  hideRightSide = false,
}: {
  pageName?: string
  showLogin?: boolean
  hideRightSide?: boolean
  source: PageSources
}) => {
  const { t } = useTranslation('@mc/persona')
  const { slug: instructorSlug } = useRouter().query
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const { trackPersonaSignClick, trackPersonaClick } = usePersonaEvents()
  const queryClient = useQueryClient()
  const isUpcomingBetaEnabled = useIsUpcomingBetaEnabled()

  const [scrolled, setScrolled] = useState(false)
  const [showNavbar, setShowNavbar] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    const controlNavbar = () => {
      // This accounts for Safari allowing the user to over scroll
      const scrollY = Math.max(window.scrollY, 0)

      if (scrollY > lastScrollY) {
        setShowNavbar(false)
      } else {
        setShowNavbar(true)
      }

      setLastScrollY(scrollY)

      if (scrollY > 350) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', controlNavbar)

    return () => {
      window.removeEventListener('scroll', controlNavbar)
    }
  }, [lastScrollY])

  // Authed
  const { user, isLoading } = useAuth0User()
  const { mutate: handleLogout } = useMutation(Auth0Service.logout, {
    onSuccess: () => {
      queryClient.clear()
      window.location.href = paths.root
    },
  })
  const loginState = user ? '(post-login)' : '(pre-login)'
  const pageResult = instructorSlug
    ? `${instructorSlug} ${pageName} ${loginState}`
    : pageName

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false)
  }

  const handleLogoClick = () => {
    trackPersonaSignClick({
      cta: 'oncall',
      location: 'top navigation',
      isUpcomingBeta: isUpcomingBetaEnabled,
      ...(pageResult && {
        additionalAnalytics: {
          page: pageResult,
        },
      }),
    })
  }

  const handleAvatarClick = (fromMobile = false) => {
    if (fromMobile) {
      setIsDropdownOpen(true)
    }
    trackPersonaClick({
      location: 'top-navigation-bar-settings',
      cta: 'settings-avatar-icon',
      action: 'settings avatar click',
      level: 'primary',
      ...(pageResult && {
        additionalAnalytics: {
          page: pageResult,
        },
      }),
    })
  }

  // Not Authed
  const getCtaToPurchaseUrl = useCtaToPurchaseUrl()

  const loginHref = getCtaToPurchaseUrl(
    paths.personaCustomAuth('login'),
    source,
  )

  const handleLoginClick = () => {
    let sourceResult: ExtendedSources | undefined
    if (source === 'bio') {
      sourceResult = user ? source : `${source}NoUser`
    } else {
      sourceResult = source
    }
    trackPersonaSignClick({
      cta: 'log in',
      location: 'top navigation',
      isUpcomingBeta: isUpcomingBetaEnabled,
      additionalAnalytics: {
        page: pageResult,
        page_source: getPageSource(
          sourceResult,
          instructorSlug as string | undefined,
        ),
      },
    })
  }

  const loginLinkIfVisible = showLogin && !isLoading && !user && (
    <Link
      href={loginHref}
      onClick={handleLoginClick}
      className='mc-text-x-small mc-text--bold'
    >
      {t('nav.login')}
    </Link>
  )

  const userImage = (
    <Image
      className='mc-corners--circle'
      src={user?.picture ?? ProfilePlaceholder}
      alt={t('common.profileAlt')}
      loader={cloudflareLoader}
      width={32}
      height={32}
    />
  )

  return (
    <>
      <header
        className={cn('uncontainer', styles.navbar, {
          [styles.scrolled]: scrolled,
          [styles.show]: showNavbar,
          [styles.hide]: !showNavbar,
        })}
      >
        <div className='mc-w-100 mc-py-4'>
          {/* Mobile */}
          <div className='align-items-center justify-content-between d-flex d-md-none mc-px-4'>
            <Link
              href={user ? paths.homepage : paths.root}
              onClick={handleLogoClick}
            >
              <Logo width='120' />
            </Link>
            {!hideRightSide && (
              <div style={{ minWidth: '1px' }}>
                {user && (
                  <Button
                    kind='link'
                    onClick={() => handleAvatarClick(true)}
                    className='mc-p-0'
                  >
                    {userImage}
                  </Button>
                )}
                {loginLinkIfVisible}
              </div>
            )}
          </div>
          {/* Desktop */}
          <div className='align-items-center justify-content-between d-none d-md-flex mc-px-10'>
            <Link
              href={user ? paths.homepage : paths.root}
              onClick={handleLogoClick}
            >
              <Logo width='140' alt={t('common.logoAlt')} />
            </Link>
            {!hideRightSide && (
              <div style={{ minWidth: '1px' }}>
                {loginLinkIfVisible}
                {isLoading ? (
                  <Loader width={50} />
                ) : (
                  user && (
                    <Dropdown placement='bottom-end'>
                      <DropdownToggle>
                        <Button
                          kind='link'
                          onClick={() => handleAvatarClick()}
                          className='mc-p-0'
                        >
                          {userImage}
                        </Button>
                      </DropdownToggle>
                      <DropdownContent className='mc-mt-3'>
                        <AccountManagementDropdown
                          onClickLogout={() => setIsLogoutModalOpen(true)}
                          showAccountManagement={true}
                          showHelpCenter={true}
                          pageResult={pageResult}
                        />
                      </DropdownContent>
                    </Dropdown>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </header>
      <AccountManagementMobile
        isOpen={isDropdownOpen}
        onClose={handleCloseDropdown}
        onClickLogout={() => setIsLogoutModalOpen(true)}
        showAccountManagement={true}
        showHelpCenter={true}
      />
      <LogoutConfirmationModal
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onLogout={handleLogout}
      />
    </>
  )
}
