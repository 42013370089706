// NOTE: ALL ROUTES in the SPA should have a corresponding route in config/routes.rb
// We do not route the catch-all route to the SPA anymore for performance reasons.
import { isEnterpriseUser } from '@mc/user-info'

export const paths = {
  activate: '/activate',
  appleTv: '/appletv',
  articles: '/articles',
  article: (articleSlug = ':articleSlug') => `/articles/${articleSlug}`,
  articlesBookmarks: '/articles-bookmarks',
  authConfirmEmail: '/auth/confirm-email',
  bogo: '/2-for-1-offer',
  bogoGift: (id = ':id') => `/bogo-gifts/${id}`,
  cancelSubscription: '/account/edit/cancel-subscription',
  cancelSubscriptionFeedback: '/account/edit/cancel-subscription/feedback',
  changePlanConfirmation: '/change-plan',
  chaptersWatch: (courseSlug = ':courseSlug', chapterSlug = ':chapterSlug') =>
    `/classes/${courseSlug}/chapters/${chapterSlug}`,
  chapterOrEpisodeWatch: (
    type: string,
    courseSlug = ':courseSlug',
    chapterSlug = ':chapterSlug',
  ) =>
    type === 'episode' || type === 'series'
      ? paths.episodesWatch(courseSlug, chapterSlug)
      : paths.chaptersWatch(courseSlug, chapterSlug),
  categories: '/categories',
  category: (categorySlug = ':categorySlug') => `/categories/${categorySlug}`,
  subcategory: (
    categorySlug: string = ':categorySlug',
    subcatSlug: string = ':subcatSlug',
  ) => `/categories/${categorySlug}#${subcatSlug}`,
  subcategoryPage: (subcategorySlug = ':subcategorySlug') =>
    `/subcategories/${subcategorySlug}`,
  checkout: '/checkout',
  checkoutPayment: (productId?: number | string, queryParams = '') => {
    if (!productId) return `/checkout?${queryParams ? `?${queryParams}` : ''}`

    return `/checkout/payment?product_id=${productId}${
      queryParams ? `&${queryParams}` : ''
    }`
  },
  checkoutGift: (query = {}) => {
    const queryParams = new URLSearchParams(query)
    queryParams.set('gift', 'true')
    return `/checkout?${queryParams.toString()}`
  },
  classPage: (courseSlug = ':courseSlug') => `/classes/${courseSlug}`,
  classOrSeriesPage: (type: string, courseSlug = ':courseSlug') =>
    type === 'series'
      ? paths.seriesPage(courseSlug)
      : paths.classPage(courseSlug),
  classRecommendations: '/find-my-classes',
  classRecommendationsWithCategories: (
    catIds: string,
    skipQ1?: string | undefined,
  ) => {
    const qp = new URLSearchParams()
    qp.set('categories', catIds)
    if (skipQ1 === 't') qp.set('wq', 't') // SKIPQ1 constant defined in @mc/next
    return `/find-my-classes?${qp.toString()}`
  },
  classRecommendationsResults: '/find-my-classes/results',
  classSelection: '/class-selection',
  content: (contentSlug = ':contentSlug') => `/content/${contentSlug}`,
  courseTypePage: (
    type: 'course' | 'series' | 'camp' | string,
    contentSlug: string,
  ) => {
    switch (type) {
      case 'camp':
        return paths.sessionsClassHome(contentSlug)
      case 'course':
        return paths.classPage(contentSlug)
      case 'series':
        return paths.seriesPage(contentSlug)
      default:
        return ''
    }
  },
  createProfile: '/profiles/create',
  discoverQuiz: '/discover-quiz',
  editProfile: (slug = ':slug') => `/profiles/${slug}/edit`,
  enterpriseHomepage: '/for-business',
  enterpriseAdmin: '/for-business/admin',
  enterpriseCart: '/for-business/cart',
  enterpriseProgressReporting: '/for-business/progress-reporting',
  enterprisePurchase: '/for-business/purchase',
  enterprisePurchaseLoading: '/for-business/purchase-loading',
  enterpriseReportsOverview: (orgId: string) =>
    `/for-business/admin/${orgId}/reports`,
  enterpriseReportsTopClasses: (orgId: string) =>
    `/for-business/admin/${orgId}/reports/top-classes`,
  enterpriseSharedContent: (orgId: string) =>
    `/for-business/admin/${orgId}/shared-content`,
  enterpriseTeams: (orgId: string, teamId: string) =>
    `/for-business/admin/${orgId}/teams/${teamId}`,
  enterpriseThankYou: '/for-business/thank-you',
  enterpriseCollective: '/for-business/collective',
  enterpriseAccelerator: '/for-business/accelerator',
  enterpriseSkillsLibrary: '/for-business/skills',
  enterpriseSkillCategory: (skillCategorySlug = ':skillCategorySlug') =>
    `/for-business/skills/${skillCategorySlug}`,
  episodesWatch: (seriesSlug = ':courseSlug', episodeSlug = ':chapterSlug') =>
    `/series/${seriesSlug}/episodes/${episodeSlug}`,
  howTo: (slug: string) => `/how-to/${slug}`,
  gift: '/gift',
  giftsRedemption: (giftId = ':id', queryParams = '') =>
    `/gifts/${giftId}${queryParams ? `?${queryParams}` : ''}`,
  guestPass: '/guest-pass',
  guestPassTermsPath: 'https://learn.masterclass.com/guest-pass-offer-terms',
  home: '/homepage',
  tabbedHome: (tab = ':tab?') => `/homepage/${tab}`,
  inbox: '/inbox',
  inboxConversation: (conversationId = ':conversationId') =>
    `/inbox/${conversationId}`,
  instructorPortal: '/instructor-portal',
  krisJennerLive: `/kris-jenner-live`,
  library: '/library-search',
  logIn: (nextPage?: string) => {
    const encodedNextPage = nextPage ? encodeURIComponent(nextPage) : ''
    return `/auth/login${nextPage ? `?next_page=${encodedNextPage}` : ''}`
  },
  manageGifts: '/manage-gifts',
  mobilePush: '/mobile-push',
  mobileUniversalHome: '/home',
  myProgress: '/my-progress',
  onboarding: '/onboarding',
  outcomePage: (outcomeSlug = ':outcomeSlug') => `/outcomes/${outcomeSlug}`,
  outcomeOnSubcatPage: (
    subcatSlug = ':subcatSlug',
    outcomeSlug = ':outcomeSlug',
  ) => `/subcategories/${subcatSlug}#${outcomeSlug}`,
  package: (packageSlug = ':packageSlug') => `/packages/${packageSlug}`,
  changePlan: '/plans',
  playlists: '/playlists',
  playlistWatch: (
    playlistSlug = ':playlistSlug',
    trackSlug: string | null = null,
  ) => `/playlist/${playlistSlug}${trackSlug ? `?track=${trackSlug}` : ''}`,
  privacy: '//privacy.masterclass.com',
  privacyPolicies: '//privacy.masterclass.com/policies',
  privacySpeechPolicy:
    '//privacy.masterclass.com/policies?name=speech-to-text-notice ',
  profiles: '/profiles',
  programsPage: (programSlug = ':programSlug') => `/programs/${programSlug}`,
  projects: (slug: string) => `/projects/${slug}`,
  promotion: '/promotion',
  promotionRedirect: (nextPath: string) =>
    `/promotion?next=${encodeURIComponent(nextPath)}`,
  refund: '/refund',
  root: '/',
  seriesPage: (seriesSlug = ':courseSlug') => `/series/${seriesSlug}`,
  sessions: () => '/sessions',
  sessionsClassHome: (campSlug = ':campSlug') =>
    `/sessions/classes/${campSlug}`,
  sessionsClassCommunity: (campSlug = ':campSlug') =>
    `/sessions/classes/${campSlug}/community`,
  sessionsClassCommunityWithModule: (
    campSlug = ':campSlug',
    moduleSlug = ':moduleSlug',
  ) => `/sessions/classes/${campSlug}/community/module/${moduleSlug}`,
  sessionsClassPost: (campSlug = ':campSlug', postId = ':postId') =>
    `/sessions/classes/${campSlug}/post/${postId}`,
  sessionsTaskPage: (campSlug = ':campSlug', taskSlug = ':taskSlug') =>
    `/sessions/classes/${campSlug}/tasks/${taskSlug}`,
  settings: '/account/edit',
  signUp: (nextPage?: string) =>
    `/auth/register${nextPage ? `?next_page=${nextPage}` : ''}`,
  shortForm: (slug = ':slug') => `/ten-minutes/${slug}`,
  ssoLogIn: (nextPage?: string) =>
    `/auth/discovery${nextPage ? `?next_page=${nextPage}` : ''}`,
  verifyEmail: (token = ':token') => `/account/verify/${token}`,
  terms: '/terms',
  termsForAI: '//learn.masterclass.com/ai-terms',
  giftTerms: 'https://learn.masterclass.com/gift-terms',

  // LABS Landing page links
  aceIt: '/ace-it',
  aceItCheckout: '/ace-it/checkout',
  aceItQuiz: '/ace-it/quiz',
  aceItQuizStep: (slug: string) => `/ace-it/quiz/${slug}`,

  amii: '/amii',
  amiiCheckout: '/amii/checkout',
  amiiQuiz: '/amii/quiz',
  amiiQuizStep: (slug: string) => `/amii/quiz/${slug}`,

  ascend: '/ascend',
  ascendCheckout: '/ascend/checkout',
  ascendQuiz: '/ascend/quiz',
  ascendQuizStep: (slug: string) => `/ascend/quiz/${slug}`,

  guided: '/guided',
  guidedQuiz: '/guided/quiz',
  guidedQuizStep: (slug: string) => `/guided/quiz/${slug}`,
  guidedCheckout: '/guided/checkout',

  monument: '/monument',
  monumentCheckout: '/monument/checkout',

  certificates: '/certificates',
  certificate: (certSlug: string = ':certSlug') => `/certificates/${certSlug}`,
  certificateCurriculum: (certSlug: string = ':certSlug') =>
    `/certificates/${certSlug}/curriculum`,
  certificateLesson: (
    certSlug: string = ':certSlug',
    lessonSlug: string = ':lessonSlug',
  ) => `/certificates/${certSlug}/lesson/${lessonSlug}`,
  certificatesCheckout: (productId: string | number) =>
    `/certificates/checkout?product_id=${productId}`,
  certificatesPayment: (productId: string | number) =>
    `/certificates/checkout/payment?product_id=${productId}`,
  certificateUnlock: (certSlug: string = ':certSlug') =>
    `/certificates/${certSlug}/reward`,
  peopleSkills: '/people-skills',
  peopleSkillsCheckout: '/people-skills/checkout',
  peopleSkillsQuiz: '/people-skills/quiz',
  peopleSkillsQuizStep: (slug: string) => `/people-skills/quiz/${slug}`,

  // NEW Product based on Persona
  wisdom: '/wisdom',
  wisdomCheckout: '/wisdom/checkout',
  wisdomQuiz: '/wisdom/quiz',
  wisdomQuizStep: (slug: string) => `/wisdom/quiz/${slug}`,

  // OLD Persona Landing
  wisdomai: '/wisdomai',
  wisdomaiCheckout: '/wisdomai/checkout',
  wisdomaiTrial: '/wisdomai/trial',
  wisdomaiCall: '/wisdomai/call',

  // Help center links
  helpCenter: '/help-center',
  helpCenterForProduct: (productSlug: string) => `/help-center/${productSlug}`,
  helpCenterAnswer: (productSlug: string, answerSlug: string) =>
    `/help-center/${productSlug}/answers/${answerSlug}`,
  giftSupport:
    '/help-center/masterclass/answers/resending-editing-and-printing-your-gift--id--Gnx31xzCRe-Dp1X5VHTOQg',
  contact:
    '/help-center/masterclass/answers/Contacting-MasterClass-Support---id--6sH8j_CwT9iEZ_BJq_p6Sg',
  faq: '/help-center',
  howToAppleTV:
    '/help-center/masterclass/answers/streaming-master-class-on-apple-tv--id--h6wtiv3BQv6dDftKRcKREg',
  howToAndroidTV:
    '/help-center/masterclass/answers/master-class-app-for-android-tv--id--10bGL6SJRfi9MOcRFNUlEA',
  howToAmazonTV:
    '/help-center/masterclass/answers/streaming-master-class-on-amazon-fire-tv--id--39PwWq5eSkuWXOlALumnlw',
  howToRokuTV:
    '/help-center/masterclass/answers/streaming-master-class-on-roku-t-vs--id--bZhx-UGUQxyBcXbQywPmpA',
  membershipFaq:
    '/help-center/masterclass/answers/about-master-class-membership-plans--id---KTOuuyVSJOaE7YwVZu2Og',
  howToRefund:
    '/help-center/masterclass/answers/requesting-a-refund--id--ffUda_F-TPqkca5o9hJc5g',
  howToGettingStartedWithRevolut:
    '/help-center/masterclass/answers/getting-started-with-revolut--id--ibWhhuMHTwSimH_2Qs58lQ',
  optusSubscriptionFAQ:
    '/help-center/masterclass-at-work/answers/master-class-at-work-faq--id--ThDJg40yTSKZCcK_9gsNFg',
  howCoursesMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  howUserMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  howAnalyticsOverviewMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  howTeamsMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  howEngagementMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  howUnlockWatchingDataInLeaderboardReport:
    '/help-center/masterclass-at-work/answers/admin-analytics-faq--id--omz_rz5-Sf6URASFnh4KuQ',
  howLeaderboardMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  masterClassAtWorkSharingContent:
    '/help-center/masterclass-at-work/answers/master-class-at-work-sharing-content--id--Tbu9P2d6Q8-BXRDEsv0t8Q',
  sharingExperience:
    '/help-center/masterclass-at-work/answers/master-class-at-work-sharing-content--id--Tbu9P2d6Q8-BXRDEsv0t8Q',
  howSkillsMetricsAreDefined:
    '/help-center/masterclass-at-work/answers/analytics-metrics-definitions--id--jpXF1pdTSESnHWo_breHCQ',
  masterClassAtWorkFaq:
    '/help-center/masterclass-at-work/answers/master-class-at-work-faq--id--ThDJg40yTSKZCcK_9gsNFg',
  supportBogo:
    '/help-center/masterclass/answers/sending-your-gift-for-buy-one-get-one-promotion--id--WVBxN-syT_GQ0HEn1ekzxA',
  supportGetRefund:
    '/help-center/masterclass/answers/requesting-a-refund--id--ffUda_F-TPqkca5o9hJc5g',
  giftNotReceivedBogo:
    '/help-center/masterclass/answers/troubleshooting-a-gift-that-was-not-received--id--Ada5iAO2Rf2JZbev3yI5RQ',
  cancelSubscriptionRenewal:
    '/help-center/masterclass/answers/cancelling-your-subscription-renewal--id--M2McWBvNTh-WNRR6llmkAw',
} as const

export const pathsWithQueryParams = {
  checkout: ({ isGift = false, query = {} }) => {
    const queryParams = new URLSearchParams(query)
    if (isGift) queryParams.set('gift', 'true')
    return `${paths.checkout}?${queryParams.toString()}`
  },
}

// Mobile Web Path Whitelist: Any path not on this list will instead render the
// Mobile-Push component for logged-in mobile web users.

// IMPORTANT: if you are adding a route that takes a param to this list (eg has an id in it),
// make sure it is formatted as a string in this array, not as a function.

// Example:
// export const mobileWebPathWhitelist = [
//  paths.articles,
//  paths.package(), <-- instead of paths.package, which is a function
//  ...
// ]

const baseWhitelist = [
  paths.activate,
  paths.appleTv,
  paths.articles,
  paths.articlesBookmarks,
  paths.bogo,
  paths.bogoGift(),
  paths.cancelSubscription,
  paths.changePlanConfirmation,
  paths.gift,
  paths.guestPass,
  paths.krisJennerLive,
  paths.mobilePush,
  paths.refund,
  paths.settings,
  paths.profiles,
  paths.manageGifts,
  paths.certificateCurriculum(),
  paths.certificateLesson(),
]

// TODO delete this conditional when consumer onboarding is available on mobile
if (isEnterpriseUser()) {
  baseWhitelist.push(paths.onboarding)
}

export const mobileWebPathWhitelist = baseWhitelist
