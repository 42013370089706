import React from 'react'
import styles from './Background.module.scss'

export const Background = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => (
  <div className={`${styles.background}${className ? ` ${className}` : ''}`}>
    {children}
  </div>
)
