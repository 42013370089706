import { Mentor } from '../../../types/mentor'

const CVaskForARaiseAudio =
  'https://static.masterclass.com/oncall-callexamples/OnCall_Car_CV_Raise_v2_Delivery_v1.m4a'
const CVhelpAtHomeAudio =
  'https://static.masterclass.com/oncall-callexamples/OnCall_TJ_CV_HelpAtHome_v3_Delivery_v1.m4a'
const MWJetLagAudio =
  'https://static.masterclass.com/oncall-callexamples/OnCall_CC_MW_JetLagParis_v1_Delivery_v1.m4a'
const MWSleepFasterAudio =
  'https://static.masterclass.com/oncall-callexamples/OnCall_Tom_MW_SleepFaster_v2_Delivery_v1.m4a'

export const mentors: Mentor[] = [
  {
    image: {
      src: 'https://static.masterclass.com/oncall_chris_voss_2x3.jpg',
      alt: 'Chris Voss',
      width: 495,
      height: 711,
    },
    slug: 'chris-voss',
    title: 'Chris Voss',
    subtitle: 'FBI Hostage Negotiator',
    keywords: ['Communication', 'Negotiation', 'Persuasion'],
    examples: [
      {
        caption: 'How do I ask for a raise?',
        audioSrc: CVaskForARaiseAudio,
      },
      {
        caption: 'Tell partner I need help at home',
        audioSrc: CVhelpAtHomeAudio,
      },
    ],
    redirectUrl: '/aimentor/chris-voss?page_source=discovery',
  },
  {
    image: {
      alt: 'Dr. Matt Walker',
      src: 'https://static.masterclass.com/oncall_matthew_walker_2x3.jpg',
      width: 495,
      height: 711,
    },
    slug: 'matthew-walker',
    title: 'Dr. Matt Walker',
    subtitle: 'Neuroscientist & Sleep Expert',
    keywords: ['Sleep', 'Productivity', 'Neuroscience'],
    examples: [
      { caption: 'How can I manage jet lag?', audioSrc: MWJetLagAudio },
      {
        caption: 'Tips for falling asleep faster',
        audioSrc: MWSleepFasterAudio,
      },
    ],
    redirectUrl: '/aimentor/matthew-walker?page_source=discovery',
  },
]

// TODO: Update coming soon mentors data
export const comingSoonMentors: Mentor[] = [
  {
    image: {
      src: 'https://static.masterclass.com/oncall_mark_cuban_1x1.jpg',
      alt: 'Mark Cuban',
      width: 495,
      height: 495,
    },
    slug: 'mark-cuban',
    title: 'Mark Cuban',
    subtitle: '',
    keywords: ['Entrepreneurship', 'Business', 'Leadership'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_gordon_ramsay_1x1.jpg',
      alt: 'Gordon Ramsay',
      width: 495,
      height: 495,
    },
    slug: 'gordon-ramsay',
    title: 'Gordon Ramsay',
    subtitle: '',
    keywords: ['Recipes', 'Cooking & Baking', 'Kitchen Skills'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_kelly_wearstler_1x1.jpg',
      alt: 'Kelly Wearstler',
      width: 495,
      height: 495,
    },
    slug: 'kelly-wearstler',
    title: 'Kelly Wearstler',
    subtitle: '',
    keywords: ['Interior & Architectural Design', 'Entertaining', 'Styling'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_levar_burton_1x1.jpg',
      alt: 'LeVar Burton',
      width: 495,
      height: 495,
    },
    slug: 'levar-burton',
    title: 'LeVar Burton',
    subtitle: '',
    keywords: ['Storytelling', 'Public Speaking', 'Communication'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_ray_dalio_1x1.jpg',
      alt: 'Ray Dalio',
      width: 495,
      height: 495,
    },
    slug: 'ray-dalio',
    title: 'Ray Dalio',
    subtitle: '',
    keywords: ['Decision Making', 'Leadership', 'Personal Development'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_richard_branson_1x1.jpg',
      alt: 'Richard Branson',
      width: 495,
      height: 495,
    },
    slug: 'richard-branson',
    title: 'Richard Branson',
    subtitle: '',
    keywords: ['Business', 'Leadership', 'Innovation'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_tan_france_1x1.jpg',
      alt: 'Tan France',
      width: 495,
      height: 495,
    },
    slug: 'tan-france',
    title: 'Tan France',
    subtitle: '',
    keywords: ['Personal Style', 'Fashion', 'Confidence'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_dkg_1x1.jpg',
      alt: 'Doris Kearns Goodwin',
      width: 495,
      height: 495,
    },
    slug: 'doris-kearns-goodwin',
    title: 'Doris Kearns Goodwin',
    subtitle: '',
    keywords: ['Leadership', 'Storytelling', 'American History'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_emily_wines_1x1.jpg',
      alt: 'Emily Wines',
      width: 495,
      height: 495,
    },
    slug: 'emily-wines',
    title: 'Emily Wines',
    subtitle: '',
    keywords: ['Wine Tasting', 'Pairing Tips', 'Wine Selection'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_daniel_negreanu_1x1.jpg',
      alt: 'Daniel Negreanu',
      width: 495,
      height: 495,
    },
    slug: 'daniel-negreanu',
    title: 'Daniel Negreanu',
    subtitle: '',
    keywords: ['Poker', 'Strategic Thinking', 'Body Language'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_brandon_mcmillan_1x1.jpg',
      alt: 'Brandon McMillan',
      width: 495,
      height: 495,
    },
    slug: 'brandon-mcmillan',
    title: 'Brandon McMillan',
    subtitle: '',
    keywords: [
      'Puppy Training',
      'Behavioral Solutions',
      'Training Fundamentals',
    ],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_bill_nye_1x1.jpg',
      alt: 'Bill Nye',
      width: 495,
      height: 495,
    },
    slug: 'bill-nye',
    title: 'Bill Nye',
    subtitle: '',
    keywords: ['Science', 'Problem Solving', 'Critical Thinking'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
  {
    image: {
      src: 'https://static.masterclass.com/oncall_gloria_steinem_1x1.jpg',
      alt: 'Gloria Steinem',
      width: 495,
      height: 495,
    },
    slug: 'gloria-steinem',
    title: 'Gloria Steinem',
    subtitle: '',
    keywords: ['Empowerment', 'Influence', 'Leadership'],
    topRightBadge: {
      backgroundColor: '#22232680',
      text: { primary: 'Coming Soon' },
    },
  },
]
