import React, { useEffect, useId, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Button, ClickOutside } from '@mc/design-system'
import classes from './ControlDropdown.module.scss'
import { FADE_DURATION_FAST } from './constants'
import { useVideo } from '../VideoContext'

interface ControlProps {
  title: string
  options: {
    label: string
    value: number
    icon?: React.ReactNode
  }[]
  icon: React.ReactNode
  onClick: (index: number) => void
  selectedValue: number
}

const DROPDOWN_CLOSE_DELAY = 500

export const ControlDropdown = ({
  title,
  icon,
  options,
  onClick,
  selectedValue,
}: ControlProps) => {
  const { height } = useVideo()

  const [open, setIsOpen] = useState(false)

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false)
      }
    }

    document.addEventListener('keydown', handleKeydown)

    return () => document.removeEventListener('keydown', handleKeydown)
  }, [setIsOpen])

  const id = useId()
  const ariaControls = `dropdown-${id}`

  return (
    <ClickOutside
      onClickOutside={() => setIsOpen(false)}
      className='d-flex flex-row align-items-center'
    >
      <Button
        kind='link'
        size='sm'
        aria-controls={ariaControls}
        aria-expanded={open}
        aria-haspopup
        symmetrical
        aria-label={title}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen((isOpen) => !isOpen)
        }}
      >
        {icon}
      </Button>

      <AnimatePresence>
        {open && (
          <motion.div
            className={`position-absolute mc-mb-9 mc-corners--rounded d-flex flex-column ${classes.dropdownContent}`}
            role='listbox'
            aria-labelledby='mobile-dropdown'
            tabIndex={0}
            id={ariaControls}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: FADE_DURATION_FAST }}
            style={{ maxHeight: 0.8 * height }}
          >
            <h3 className='mc-px-4 mc-py-2 mc-text-x-small mc-opacity--muted mc-mr-3'>
              {title}
            </h3>

            <ul className='mc-px-4 mc-py-2' style={{ overflowY: 'auto' }}>
              {options.map(({ label, value, icon: optionIcon }) => (
                <li
                  role='option'
                  aria-selected={selectedValue === value}
                  key={label}
                >
                  <Button
                    kind={selectedValue === value ? 'secondary' : 'link'}
                    className='mc-w-100 justify-content-start'
                    aria-pressed={selectedValue === value}
                    size='xs'
                    onClick={() => {
                      onClick(value)
                      // delay closing dropdown a bit, so the user can see their selection
                      setTimeout(() => {
                        setIsOpen(false)
                      }, DROPDOWN_CLOSE_DELAY)
                    }}
                  >
                    {label}
                    {optionIcon}
                  </Button>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </ClickOutside>
  )
}
