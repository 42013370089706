import React, { useRef, useEffect, useCallback, useReducer } from 'react'
import cn from 'classnames'
import { omit, pick } from 'lodash'
import Hls, { HlsListeners } from 'hls.js'

import { useTranslation, withI18nContext } from '@mc/i18n'

import { VideoContext } from './VideoContext'
import i18nInstance from '../i18n/i18nInstance'
import { useLoadVideoById } from './useLoadVideoById'
import { VisitSessionProvider } from './analytics/VisitSessionProvider'
import {
  VideoAutoplay,
  VideoControls,
  VideoKeyboard,
  VideoMediaAnalytics,
  VideoQoS,
  VideoTrack,
} from '.'
import { registerActivePlayer, useVideoSingleton } from './useVideoSingleton'
import { reducer, initialState } from './videoReducer'
import { FullscreenContainer, useFullscreen } from './FullscreenContainer'

import './video.scss'
import { useInteraction } from './useInteraction'
import { VideoLoop } from './VideoLoop'
import { isIOS } from './isIOS'

type AspectRatio = '16x9' | '4x3' | '2x3' | '1x1' | '9x16' | 'auto'
type Fit = 'fit' | 'cover' | 'fill'

/**
 * Represents the current playing state of the video.
 * - idle    = the video has not yet been played
 * - playing = the video is playing and can be paused
 * - paused  = the video has been paused and can be played
 * - ended   = the video has stopped because it played to the end
 */
export type State = 'idle' | 'playing' | 'paused' | 'ended'
/**
 * Represents the current ready state of the video.
 * - loading = the video has not yet loaded
 * - ready   = the video has loaded and is ready for playback
 */
export type ReadyState = 'loading' | 'ready'

export type Source = {
  src: string
  type: string
}

export type Track = {
  src: string
  srcLang: string
  label: string
  kind: TextTrack['kind']
  default?: boolean
}

export type AudioTrack = {
  id: number
  url: string
  name: string
}

export type QualityLevelOption = {
  name: string
  value: number
  height: number | null
  bitrate: number
  quality: string
  hd: boolean
}

export const minMax = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max)

export type VideoProps = Omit<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  'ref' | 'src' | 'type' | `on${string}`
> &
  Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'onDoubleClick'> & {
    /**
     * The aspect ratio of the video
     */
    aspectRatio?: AspectRatio
    /**
     * Overlays or custom controls to be displayed over of the video frame
     */
    children?: React.ReactNode
    /**
     * How to fit the video when its contents don't match the aspect ratio
     */
    fit?: Fit
    /**
     * Poster to display before the video loads
     * If not provided, will be populated by loaded video metadata
     */
    poster?: string
    /**
     * Provided for backwards compatibility for analytics
     * @deprecated Use mediaUuid to load a video
     */
    videoId?: string
    /**
     * Loads the video from a given Edge Media API UUID
     * Takes precedence over any given source
     */
    mediaUuid: string | undefined
    /**
     * Loads the video from a given Source
     * Provide a source for the video with either mediaUuid or source
     */
    source?: Source
    /**
     * Exempt the player from pausing other players, or being paused.
     * Mostly useful for muted background players
     */
    exemptSingleton?: boolean
    /**
     * Enables key bindings when the video is active
     */
    keyboardEvents?: boolean
  }

export const BaseVideo = withI18nContext(i18nInstance)(({
  aspectRatio = '16x9',
  children,
  className,
  fit = 'fit',
  mediaUuid,
  videoId,
  source: initialSource,
  exemptSingleton,
  autoPlay,
  controls,
  loop,
  keyboardEvents,
  ...props
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { t } = useTranslation('@mc/video')

  const [videoState, dispatch] = useReducer(reducer, initialState)

  // these are read-only directly from the video manifest
  // so they don't need to go into state
  const { tracks, poster, source, decoder, hls } = useLoadVideoById({
    mediaUuid,
    explicitSource: initialSource,
    videoRef,
  })

  useEffect(() => {
    if (!hls) {
      return
    }

    // LEVEL_SWITCHED fires before LEVEL_LOADED, but only on second level and beyond
    hls.on(Hls.Events.LEVEL_SWITCHED, (_, data) =>
      dispatch({
        type: 'qualityLevelsUpdated',
        payload: {
          levels: hls.levels,
          indexActual: data.level,
        },
      }),
    )

    hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (_, { audioTracks: hlsTracks }) =>
      dispatch({ type: 'audioTracksUpdated', payload: hlsTracks }),
    )

    hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, (_, { id }) =>
      dispatch({
        type: 'audioTrackChanged',
        payload: id,
      }),
    )
  }, [hls])

  useEffect(() => {
    if (!hls) return () => {}

    const listener: HlsListeners[typeof Hls.Events.LEVEL_LOADED] = (
      _,
      data,
    ) => {
      if (videoState.qualityActual === null) {
        dispatch({
          type: 'qualityLevelsUpdated',
          payload: {
            levels: hls.levels,
            indexActual: data.level,
          },
        })
      }
    }

    // LEVEL_LOADED fires on first level
    hls.on(Hls.Events.LEVEL_LOADED, listener)

    return () => hls.off(Hls.Events.LEVEL_LOADED, listener)
  }, [videoState.qualityActual, hls])

  useVideoSingleton({
    videoRef,
    setActive: (active: boolean) =>
      dispatch({ type: 'activated', payload: active }),
    disable: exemptSingleton,
  })

  // if `source` is null, it means a new load request has been initiated
  // if it was populated and now it's not, reset
  // if it wasn't populated and now it is, reset
  // if it is different than it was before, reset
  // only don't reset if it hasn't changed
  const prevSource = useRef<typeof source>()
  if (
    prevSource.current?.src !== source?.src ||
    prevSource.current?.type !== source?.type
  ) {
    // pass along the muted prop; no onVolumeChange is fired
    // if muted is initially true
    dispatch({ type: 'init', payload: { muted: props.muted } })
    prevSource.current = source
  }

  const { fullscreen, toggleFullscreen } = useFullscreen()

  const setTime = useCallback(
    (newTime: number) => {
      if (!videoRef.current) return
      videoRef.current.currentTime = minMax(newTime, 0, videoState.duration)
    },
    [videoState.duration],
  )

  const play = useCallback(
    ({ autoPlay: wasAutoPlayed = false }: { autoPlay?: boolean } = {}) => {
      if (!videoRef.current) return Promise.resolve()
      dispatch({ type: 'autoPlayed', payload: wasAutoPlayed })
      return videoRef.current.play()
    },
    [],
  )

  const pause = useCallback(() => {
    if (
      !videoRef.current ||
      videoState.state !== 'playing' ||
      videoState.waiting
    )
      return

    videoRef.current.pause()
  }, [videoState.state, videoState.waiting])

  const togglePlay = useCallback((): Promise<void> => {
    if (!videoRef.current) return Promise.resolve()

    if (videoState.state === 'playing') {
      pause()
      return Promise.resolve()
    } else {
      return play()
    }
  }, [play, pause, videoState.state])

  const syncTextTracks = useCallback(
    (idx: number = videoState.track) => {
      const video = videoRef.current
      if (!video) return
      if (idx >= video.textTracks.length) return

      for (let i = 0; i < video.textTracks.length; i++) {
        if (i === idx) {
          // on iOS, show the native text tracks
          // on other platforms, show our custom rendered tracks
          video.textTracks[i].mode = isIOS() ? 'showing' : 'hidden'
        } else {
          video.textTracks[i].mode = 'disabled'
        }
      }

      dispatch({ type: 'trackChanged', payload: idx })
    },
    [videoState.track],
  )

  const handleInteraction = useInteraction(dispatch)

  /**
   * This is state that may change multiple times within a single render cycle
   * so a reducer or `useState` is not suitable
   */
  const seekingPreviousState = useRef<State>('idle')

  const isTouch = useRef(false)

  const classes = cn(
    {
      'mc-video': true,
      'mc-h-100': true,
      'mc-w-100': true,
      [`mc-video--fit-${fit}`]: fit,
      [`mc-video--state-${videoState.state}`]: videoState.state,
      [`mc-video--${aspectRatio}`]: true,
      'mc-video--interacting': videoState.interacting,
    },
    className,
  )

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={classes}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
      ref={(el) => {
        if (!el) return
        // offsetWidth performs better than clientWidth or getComputedStyle
        const { offsetWidth, offsetHeight } = el
        if (
          videoState.width !== offsetWidth ||
          videoState.height !== offsetHeight
        ) {
          dispatch({
            type: 'sizeUpdated',
            payload: {
              width: offsetWidth,
              height: offsetHeight,
            },
          })
        }
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        // these props can be overridden
        playsInline
        tabIndex={-1}
        poster={poster}
        {...omit(props, ['onClick', 'onDoubleClick'])}
        // the `key` prop ensures all children are umounted/remounted (along with any internal state) whenever source changes
        key={source?.src}
        ref={videoRef}
        className='mc-video__video'
        crossOrigin='anonymous'
        preload='auto'
        onTouchStart={() => {
          isTouch.current = true
        }}
        onMouseMove={() => {
          // Ignore extra `mousemove` fired by Mobile Safari before every `click`
          if (!isTouch.current) {
            handleInteraction()
          }
        }}
        onKeyDown={(e) => {
          if (e.key === ' ') {
            togglePlay()
            e.preventDefault()
            e.stopPropagation()
          }
          handleInteraction()
        }}
        onClick={(e) => {
          if (!e.defaultPrevented) {
            handleInteraction()
            if (videoState.interacting) {
              togglePlay()
            }
          }
        }}
        onError={(e) => {
          const { error } = e.currentTarget
          if (error) {
            dispatch({ type: 'error', payload: error })
          }
        }}
        onPlay={(e) => {
          registerActivePlayer(videoEl(e))
          dispatch({ type: 'play' })
        }}
        onPlaying={() => {
          syncTextTracks()
          dispatch({ type: 'playing' })
        }}
        onPause={() => dispatch({ type: 'pause' })}
        onEnded={() => dispatch({ type: 'ended' })}
        onTimeUpdate={(e) =>
          dispatch({ type: 'timeUpdated', payload: videoEl(e).currentTime })
        }
        onProgress={(e) => {
          const { buffered } = videoEl(e)
          if (buffered.length)
            dispatch({
              type: 'bufferUpdated',
              payload: buffered.end(buffered.length - 1),
            })
        }}
        onDurationChange={(e) =>
          dispatch({
            type: 'durationChanged',
            payload: videoEl(e).duration,
          })
        }
        onVolumeChange={(e) =>
          dispatch({ type: 'volumeChanged', payload: videoEl(e) })
        }
        onLoadedMetadata={() => {
          const video = videoRef.current
          if (!video) return

          video.textTracks.addEventListener('change', () => {
            let activeIdx = -1

            for (let i = 0; i < video.textTracks.length; i++) {
              if (video.textTracks[i].mode !== 'disabled') {
                activeIdx = i
              }
            }

            if (
              activeIdx > -1 &&
              video.textTracks[activeIdx].kind === 'subtitles'
            ) {
              // This is a weird dance
              //
              // iOS Safari _adds a redundant textTrack_ that is not present in `tracks`
              // it has the same srcLang as the original, but a kind of 'subtitles' instead of 'captions'
              // it then uses only the "subtitle"-kinded tracks in the fullscreen native UX
              // however, our custom VideoClosedCaption does not know about this redundant track,
              // it only uses captions, which are the only kind returned in `text_tracks` by the metadata API
              //
              // so when the active track has been changed to a "subtitles" track, we know
              // it is an iOS-synthesized redundant track
              // we want to automatically select the corresponding "captions" track instead
              // to keep the fullscreen native view and the normal custom view in sync
              //
              // as far as I can tell this behavior is undocumented, but should not affect other browsers

              // find the actual track from the source API with the desired language
              const sourceTrack = tracks.find(
                (trk) => trk.srcLang === video.textTracks[activeIdx].language,
              )

              for (let i = 0; i < video.textTracks.length; i++) {
                // look up the textTrack with the language & kind present in the API source track
                if (
                  video.textTracks[i].language === sourceTrack?.srcLang &&
                  video.textTracks[i].kind === sourceTrack?.kind
                ) {
                  // make the real track active instead of the browser-synthesized track
                  video.textTracks[i].mode = isIOS() ? 'showing' : 'hidden'
                  activeIdx = i
                } else {
                  video.textTracks[i].mode = 'disabled'
                }
              }
            }

            dispatch({ type: 'trackChanged', payload: activeIdx })
          })

          for (let i = 0; i < video.textTracks.length; i++) {
            // "disabled" tracks do not emit cuechange
            // so we can hook these events up to all tracks at the beginning and leave it
            video.textTracks[i].addEventListener('cuechange', (ev) => {
              const currentTrack = ev.currentTarget as TextTrack | null

              dispatch({
                type: 'textTrackCuesUpdated',
                payload: currentTrack?.activeCues ?? null,
              })
            })
          }

          // We don't need to yet, but eventually we should also hook up addTrack and removeTrack events

          syncTextTracks()
          dispatch({ type: 'ready' })
        }}
        onRateChange={(e) =>
          dispatch({ type: 'speedChanged', payload: videoEl(e).playbackRate })
        }
        onWaiting={() => dispatch({ type: 'waiting' })}
        onContextMenu={(e) => e.preventDefault()}
      >
        {tracks.map((trk) => (
          <track
            key={trk.src}
            {...pick(trk, [
              'src',
              'srcLang',
              'default',
              'kind',
              'mode',
              'label',
            ])}
          />
        ))}
        {t('video.notSupported')}
      </video>
      {/* `videoState` is not automatically reset when source changes, instead we explicitly dispatch `init` */}
      <VideoContext.Provider
        value={{
          ...videoState,
          autoPlay,
          fullscreen,
          poster,
          source,
          tracks,
          decoder,

          pause,
          play,
          restart: useCallback(() => {
            if (!videoRef.current) return Promise.resolve()
            setTime(0)
            return play()
          }, [play, setTime]),
          setMuted: useCallback((m: boolean) => {
            if (!videoRef.current) return
            videoRef.current.muted = m
          }, []),
          setSpeed: useCallback((newSpeed: number) => {
            if (!videoRef.current) return
            videoRef.current.playbackRate = newSpeed
          }, []),
          seekingTo: useCallback(
            (newTime: number) => {
              if (!videoRef.current) return
              dispatch({ type: 'seeking', payload: newTime })
              seekingPreviousState.current = videoState.state
              pause()
              setTime(newTime)
            },
            [videoState.state, pause, setTime],
          ),
          seekTo: useCallback(
            (newTime: number) => {
              if (!videoRef.current) return Promise.resolve()
              dispatch({ type: 'seeked', payload: newTime })
              setTime(newTime)

              if (seekingPreviousState.current === 'playing') {
                return play()
              } else {
                return Promise.resolve()
              }
            },
            [setTime, play],
          ),
          setTrack: useCallback(
            (newTrackIdx: number) => {
              syncTextTracks(newTrackIdx)
            },
            [syncTextTracks],
          ),
          setVolume: useCallback((newVolume: number) => {
            if (!videoRef.current) return
            videoRef.current.volume = minMax(newVolume, 0, 1)
            videoRef.current.muted = false
          }, []),

          setAudioTrack: useCallback(
            (newTrack: number) => {
              if (!hls) return
              hls.audioTrack = newTrack
            },
            [hls],
          ),
          setQualityLevelValue: useCallback(
            (value: number) => {
              if (!hls) return

              // Hls.js does a weird thing when setting currentLevel:
              // value === -1 is valid, but getting currentLevel will never return -1
              // instead, setting currentLevel = -1:
              // - sets `autoLevelEnabled` to `true`, and
              // - currentLevel becomes the automatically selected level
              hls.currentLevel = value

              // There is no Hls.js event that reliably fires when the internal level changes,
              // so we have to update the state directly
              // see https://github.com/video-dev/hls.js/issues/5128
              dispatch({
                type: 'qualityLevelSelected',
                payload: {
                  levels: hls.levels,
                  userValue: value,
                },
              })
            },
            [hls],
          ),

          handleInteraction,

          toggleFullscreen: useCallback(() => {
            // Safari iOS only supports fullscreen directly on a video element
            // This lets Apple prevent web-based games, but also
            // prevents us from having custom video controls/overlays on fullscreen video on iOS
            // https://developer.apple.com/documentation/webkitjs/htmlvideoelement/1628805-webkitsupportsfullscreen
            if (
              !document.fullscreenEnabled &&
              videoRef.current?.webkitSupportsFullscreen
            ) {
              videoRef.current?.webkitEnterFullscreen?.()
            } else {
              toggleFullscreen()
            }
          }, [toggleFullscreen]),
          togglePlay,
          toggleMute: useCallback(() => {
            if (!videoRef.current) return
            videoRef.current.muted = !videoState.muted
            if (videoState.muted && videoRef.current.volume === 0) {
              videoRef.current.volume = 1
            }
          }, [videoState.muted]),
        }}
      >
        {/* don't reset monitoring when source changes */}
        <VideoQoS
          videoRef={videoRef}
          hls={hls}
          videoId={videoId}
          mediaUuid={mediaUuid}
        />

        {/* reset all children when source changes */}
        <React.Fragment key={source?.src}>
          <VideoTrack />
          {loop && <VideoLoop />}
          {autoPlay && <VideoAutoplay />}
          {controls && <VideoControls />}
          {keyboardEvents && <VideoKeyboard el={videoRef.current} />}
          {children}
        </React.Fragment>
      </VideoContext.Provider>
    </div>
  )
})

BaseVideo.displayName = 'BaseVideo'

const videoEl = (
  e: React.SyntheticEvent<HTMLVideoElement, Event>,
): HTMLVideoElement => e.target as HTMLVideoElement

/**
 * The basic, unadorned Video element, with no controls.
 * Includes Mux tracking.
 *
 * Load a video with either the `source` or `mediaUuid` props,
 * only one is necessary.
 *
 * To enable Media Analytics tracking, wrap in a `TrackingContext.Provider`
 * and pass in `content_id`, `content_type`, and `content_title`.
 *
 * To add overlays, settings, or other functionality,
 * pass children. A suite of standard overlays is available via `VideoControls`;
 * see `ui/video/src/Video/controls` for more.
 *
 * Any children can read from `VideoContext` via the `useVideo` hook.
 *
 * Use `VideoOverlay` to display a component when the video is in a particular state.
 */
export const Video = ({
  children,
  className,
  ...props
}: { children?: React.ReactNode } & VideoProps) => (
  <VisitSessionProvider>
    <FullscreenContainer className={className}>
      <BaseVideo keyboardEvents className={className} {...props}>
        {children}
        <VideoMediaAnalytics />
      </BaseVideo>
    </FullscreenContainer>
  </VisitSessionProvider>
)
