import { get, has, set } from 'lodash'

declare global {
  interface Window {
    gon?: Record<string, unknown>
    __SERVER_DATA?: Record<string, unknown>
  }
}

export const parseFromGon = () => {
  const gonObject: Record<string, unknown> = {}

  if (typeof window !== 'undefined' && window?.__SERVER_DATA) {
    Object.entries(window?.__SERVER_DATA).forEach((entry) => {
      const [key, val] = entry
      if (typeof val === 'string' && val.length > 0) {
        try {
          gonObject[key] = JSON.parse(val)
        } catch (_) {
          gonObject[key] = val
        }
      } else {
        gonObject[key] = val
      }
    })
  }

  return gonObject
}

const gonObject = parseFromGon()

// make available for coffeescript & BA
// This usage of `window` cannot be correctly verified by the eslint rule
// ssr-friendly/no-dom-globals-in-module-scope. :(
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
if (typeof window !== 'undefined' && !window.gon) {
  window.gon = gonObject
}

export default {
  ...gonObject,
  get: (path: string) => get(gonObject, path),
  set: (path: string, value: unknown) => set(gonObject, path, value),
  has: (path: string) => has(gonObject, path),
}
