import { GetServerSideProps } from 'next'

import {
  PERSONA_UPCOMING_BETA_FLAG,
  LoggedOutLandingPage,
  LoggedOutLandingBetaPage,
  paths,
} from '@mc/persona-client'

import { getBucket } from 'libs/server/growthbook/getBucket'

import {
  LOGGED_OUT_LANDING_TOPICS,
  LOGGED_OUT_LANDING_TESTIMONIALS,
} from './mocks/loggedOutLanding'
import { getOrRefreshAccessToken } from './utils/getOrRefreshAccessToken'

const LoggedOutHome = ({
  isUpcomingBetaEnabled,
}: {
  isUpcomingBetaEnabled: boolean
}) =>
  isUpcomingBetaEnabled ? (
    <LoggedOutLandingBetaPage
      topics={LOGGED_OUT_LANDING_TOPICS}
      testimonals={LOGGED_OUT_LANDING_TESTIMONIALS}
    />
  ) : (
    <LoggedOutLandingPage
      topics={LOGGED_OUT_LANDING_TOPICS}
      testimonals={LOGGED_OUT_LANDING_TESTIMONIALS}
    />
  )

LoggedOutHome.pageSettings = {
  title: 'MasterClass On Call',
  // This is the name of the page for tracking purposes, no need to send page to track events
  pageName: 'persona landing page',
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  // Redirect the user if exists to the /homepage
  const session = await getOrRefreshAccessToken({
    req: ctx.req,
    res: ctx.res,
  })

  if (session) {
    return {
      redirect: {
        destination: paths.homepage,
        permanent: false,
      },
    }
  }

  const upcomingBetaBucket = await getBucket(ctx, PERSONA_UPCOMING_BETA_FLAG)
  return {
    props: {
      isUpcomingBetaEnabled: upcomingBetaBucket === 'variant_1',
    },
  }
}

export default LoggedOutHome
