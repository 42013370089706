import React, { createContext } from 'react'
import { useQuery } from '@tanstack/react-query'

import { SubscriptionsService, User, UsersService } from '@mc/persona-api'

import { Auth0Service } from '../../services/Auth0Service'

type Subscription = {
  resourceIdentifier?: string
  gateway?: string
  productDisplayName?: string
  renewsAt?: string
  expiresAt?: string
  renewalCanceled?: boolean
}

type TContext = {
  user: { email: string; name: string; picture?: string } | undefined
  me: User | undefined
  isLoading: boolean
  subscription?: Subscription
  isLoadingMe: boolean
  isLoadingSubscription: boolean
  refetchSubscription: () => void
}

export const Auth0UserContext = createContext<TContext>({
  user: undefined,
  isLoading: false,
  me: undefined,
  isLoadingMe: false,
  isLoadingSubscription: false,
  refetchSubscription: () => {},
})

const WrapperMVP = ({ children }: { children: React.ReactNode }) => {
  // Needed API call to change from reservation entitlement to entitlement
  const { data: userMCMe, isLoading: isLoadingMe } = useQuery({
    queryFn: () => UsersService.getUser({ uuid: 'me' }),
    queryKey: ['me'],
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const { data: userMVP, isLoading: isLoadingMVP } = useQuery({
    queryFn: Auth0Service.userInfo,
    queryKey: ['userInfo'],
    retry: false,
    refetchOnWindowFocus: false,
  })

  const {
    data: subscription,
    failureCount,
    isFetched,
    refetch: refetchSubscription,
  } = useQuery({
    queryFn: () => SubscriptionsService.getSubscription({ id: 'me' }),
    queryKey: ['subscription'],
    retry: 3,
    enabled: !!userMCMe,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const subscriptionLoaded = failureCount > 0 || isFetched

  return (
    <Auth0UserContext.Provider
      value={{
        user: userMVP,
        isLoading: isLoadingMVP,
        me: userMCMe,
        subscription,
        isLoadingMe,
        isLoadingSubscription: !subscriptionLoaded,
        refetchSubscription,
      }}
    >
      {children}
    </Auth0UserContext.Provider>
  )
}

export const Auth0UserProvider = ({
  children,
}: {
  children: React.ReactNode
}) => <WrapperMVP>{children}</WrapperMVP>
