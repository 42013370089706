import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useTranslation } from '@mc/i18n'
import {
  Button,
  IconFullScreenEnterDefault,
  IconPauseDefault,
  IconPlayDefault,
  IconVolumeOffDefault,
  IconVolumeOnDefault,
  Tile,
  TileCaption,
} from '@mc/design-system'
import { Video, VideoEventHandlers, useVideo } from '@mc/video'
import { TrackingContainer } from '@mc/visibility'

import { TrackingSectionContainer } from '../../../components'
import { ROW_TRACKING } from '../utils'
import { usePersonaTrack } from '../../../tracking'

import styles from './VideoSection.module.scss'

const PlayBtn = ({ paused }: { paused: boolean }) => (
  <>{paused ? <IconPlayDefault size='sm' /> : <IconPauseDefault size='sm' />}</>
)

const MuteBtn = ({ muted }: { muted: boolean }) => (
  <>
    {muted ? (
      <IconVolumeOffDefault size='sm' />
    ) : (
      <IconVolumeOnDefault size='sm' />
    )}
  </>
)

const onDoubleClickProp = {
  onDoubleClick: (event: React.MouseEvent) => {
    event.stopPropagation()
  },
}

export const VideoSection = ({
  rowAnalytics = ROW_TRACKING.raiseYourExpectations,
}: {
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
    isUpcomingBeta: boolean
  }
}) => {
  const { t } = useTranslation('@mc/persona')
  const { track } = usePersonaTrack()
  const { slug: instructorSlug } = useRouter().query
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isVideoContainerVisible, setIsVideoContainerVisible] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)

  const handleVideoButtonClick = ({
    onClick,
    action,
  }: {
    onClick?: () => void
    action:
      | 'play'
      | 'pause'
      | 'mute'
      | 'unmute'
      | 'enter full screen'
      | 'exit full screen'
  }) => {
    track('Persona Sign Click', {
      location: rowAnalytics.rowName,
      // Applies only for bio-page, otherwise undefined is not sent
      instructor_slug: instructorSlug,
      video_button: action,
      is_upcoming_beta: rowAnalytics.isUpcomingBeta,
    })
    onClick?.()
  }

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div className='mc-py-10'>
        <h2 className='mc-text-h1 mc-text--center mc-mb-10'>
          {t('upcomingBetaLanding.raiseYourExpectations.title')}
        </h2>
        <TrackingContainer onVisibilityChange={setIsVideoContainerVisible}>
          <Tile aspectRatio='16x9' className='mc-mx-md-12'>
            <Video
              mediaUuid='6931041d-28f3-4d5d-988b-7a06d79aabd5'
              loop
              playsInline
              muted
              className='mc-corners--lg mc-w-100'
              onDoubleClick={() => {
                handleVideoButtonClick({
                  action: isFullScreen
                    ? 'exit full screen'
                    : 'enter full screen',
                })
                setIsFullScreen((prev) => !prev)
              }}
              onClick={(event) => {
                event.stopPropagation()
                handleVideoButtonClick({
                  action: isPlaying ? 'pause' : 'play',
                })
              }}
            >
              <div className='mc-corners--lg' />
              <VideoControls
                onSetIsPlaying={setIsPlaying}
                onVideoButtonClick={handleVideoButtonClick}
                isVideoContainerVisible={isVideoContainerVisible}
              />
            </Video>
          </Tile>
        </TrackingContainer>
      </div>
    </TrackingSectionContainer>
  )
}

interface VideoControlsProps {
  onVideoButtonClick: ({
    action,
    onClick,
  }: {
    action:
      | 'play'
      | 'pause'
      | 'mute'
      | 'unmute'
      | 'enter full screen'
      | 'exit full screen'
    onClick?: () => void
  }) => void
  onSetIsPlaying: (isPlaying: boolean) => void
  isVideoContainerVisible: boolean
}

const VideoControls = ({
  onVideoButtonClick,
  isVideoContainerVisible,
  onSetIsPlaying,
}: VideoControlsProps) => {
  const { t } = useTranslation('@mc/persona')
  const {
    play,
    pause,
    state,
    togglePlay,
    toggleMute,
    muted,
    fullscreen,
    toggleFullscreen,
  } = useVideo()

  useEffect(() => {
    if (isVideoContainerVisible) {
      play()
    } else {
      pause()
    }
  }, [isVideoContainerVisible, pause, play])

  return (
    <>
      <VideoEventHandlers
        onPlay={() => onSetIsPlaying(true)}
        onPause={() => onSetIsPlaying(false)}
      />
      <TileCaption position='left bottom'>
        <Button
          aria-label={
            state === 'playing'
              ? t('upcomingBetaLanding.raiseYourExpectations.pause')
              : t('upcomingBetaLanding.raiseYourExpectations.play')
          }
          className={`mc-corners--circle mc-p-3 mc-mr-3 ${styles.button}`}
          kind='secondary'
          onClick={(event) => {
            event.stopPropagation()
            onVideoButtonClick({
              onClick: togglePlay,
              action: state === 'playing' ? 'pause' : 'play',
            })
          }}
          {...onDoubleClickProp}
        >
          <PlayBtn paused={state === 'paused'} />
        </Button>
        <Button
          aria-label={
            muted
              ? t('upcomingBetaLanding.raiseYourExpectations.unmute')
              : t('upcomingBetaLanding.raiseYourExpectations.mute')
          }
          className={`mc-corners--circle mc-p-2 ${styles.button}`}
          kind='secondary'
          onClick={(event) => {
            event.stopPropagation()
            onVideoButtonClick({
              onClick: toggleMute,
              action: muted ? 'unmute' : 'mute',
            })
          }}
          {...onDoubleClickProp}
        >
          <MuteBtn muted={muted} />
        </Button>
      </TileCaption>
      <TileCaption position='right top'>
        <Button
          aria-label={t('upcomingBetaLanding.raiseYourExpectations.fullscreen')}
          className={`mc-corners--circle mc-p-2 ${styles.button}`}
          kind='secondary'
          onClick={(event) => {
            event.stopPropagation()
            onVideoButtonClick({
              onClick: toggleFullscreen,
              action: fullscreen ? 'exit full screen' : 'enter full screen',
            })
          }}
          {...onDoubleClickProp}
        >
          <IconFullScreenEnterDefault size='sm' />
        </Button>
      </TileCaption>
    </>
  )
}
